<template>

    

    <div style="padding:20px;">


        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>

                <div style="">
                    <h1 >Drives Importantes Sergio</h1>
                </div>

                <div style="margin-top:20px;margin-left:30px;">
                    <a target="_blank"
                        href="https://docs.google.com/document/d/1xBLBCt5wTGPeBXXlqkGyn2Md7a8Ty2c0XZqhV8kaf1o/edit?usp=sharing ">
                        Documento geral Sergio
                    </a>
                </div>

                <div style="margin-top:20px;margin-left:30px;">
                    <a target="_blank"
                        href="https://docs.google.com/spreadsheets/d/16aDAoOw8tHAUHo_fdDEfHCzoPvRm5kJ8WehDjcVVhB0/edit?usp=sharing">
                        Link da planilha de acompanhamento dos clientes
                    </a>
                </div>

                <div style="margin-top:20px;margin-left:30px;">
                        Acesso Drives: economysoftwaremkt@gmail.com <br>
                        senha: 32Bb12578@!<br><br>
                        Acesso Drives: contato@economysoftware.com.br<br>
                        senha: AuC<2|U8q53~2a|l41@DsK <br>
                </div>

                <div style="margin-top:20px;margin-left:30px;">
                    <a target="_blank"
                        href="https://docs.google.com/spreadsheets/d/16aDAoOw8tHAUHo_fdDEfHCzoPvRm5kJ8WehDjcVVhB0/edit?usp=sharing">
                        Acesso Drives: economysoftwaremkt@gmail.com 
                        senha: 32Bb12578@!
                    </a>
                </div>





            </v-card-text>
               
        </v-card>


    </div>

    
</template>

<script>

export default {
    name:'Robos',
    components: {
    },
    data(){
        return{
            
        }
    },
    watch: {
        
    },
    methods:{
            
    },

    
    async mounted(){

    }
    
}
</script>

<style>
    .linhaFormulario{
        display: flex;
        flex-direction: row;
        /* background-color:red; */
        padding-left: 10%;
        padding-right: 10%;
    }

    .celularCampoForm{
        width:25%;
        padding:10px;
        /* background-color: green; */
    }

    .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
        color: rgba(0, 0, 0);
    }

</style>
